@layer components {
	/* Menu Hamburger Button */
	button.menu {
		& span {
			display: block;
			width: 60%;
			height: 4px;
			border-radius: 2px;
			position: relative;
			@apply bg-white;

			&::before,
			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 4px;
				@apply bg-white;
				left: 0;
				top: 0;
				border-radius: 2px;
				transition: 250ms ease;
			}

			&::before {
				transform: translateY(-8px);
			}

			&::after {
				transform: translateY(8px);
			}
		}
	}

	.nav-open {
		& nav.main-nav {
			display: block;
		}
		& button.menu {
			& span {
				background-color: transparent;

				&::before {
					opacity: 1;
					transform: translateY(0px) rotate(45deg);
				}
				&::after {
					opacity: 1;
					transform: translateY(0px) rotate(-45deg);
					bottom: 0;
				}
			}
		}
	}

	input[type='text'],
	input[type='email'],
	input[type='tel'],
	select {
		@apply border border-gray-300 rounded shadow-sm block w-full p-2 pt-3 text-base;

		&:focus {
			@apply border-primary;
		}

		&.error {
			@apply border-red-700;
		}
	}

	label {
		@apply relative top-2.5 left-1.5 bg-white px-1 text-sm text-gray-700 z-10;
	}

	[type='radio'] {
		& + label {
			@apply static top-0 left-0 font-normal text-base z-auto;
		}

		&:checked + label {
			@apply bg-green-100;
		}
	}

	.form-field-error {
		@apply text-sm font-bold px-2 text-red-700;
	}

	.no-js {
		& .form-footer {
			display: none;
		}
		& .form-step:last-child .form-footer {
			display: flex;

			& .form-previous {
				display: none;
			}
		}
	}

	a.button,
	button.button {
		@apply bg-secondary text-black px-6 py-2 rounded font-bold no-underline inline-block disabled:bg-gray-200 disabled:text-gray-600;

		/* purgecss start ignore */
		&:hover {
			@apply transition shadow-md bg-yellow-400 disabled:bg-gray-300 disabled:text-gray-600;
		}

		&:active {
			@apply ring ring-offset-2 ring-yellow-400;
		}

		&:focus {
			@apply ring ring-offset-2 ring-yellow-300 outline-none shadow-md;
		}
		/* purgecss end ignore */

		&.secondary {
			@apply bg-secondary text-white;
		}

		&.full {
			@apply block w-full;
		}

		&.large {
			@apply py-4;
		}
	}

	details {
		& summary {
			position: relative;
			padding-left: 3rem;
			list-style: none;

			/* purgecss ignore */
			&::-webkit-details-marker {
				display: none;
			}

			&::before {
				content: '+';
				width: 3rem;
				margin: -1rem 1rem -1rem 0;
				padding: 1rem 0;
				position: absolute;
				left: 0;
				text-align: center;
				font-size: 1.5rem;
				box-sizing: border-box;
				height: 100%;
			}
		}

		&[open] {
			& summary {
				&::before {
					content: '–';
				}
			}
		}
	}

	#alert-banner {
		& .banner {
			@apply transform transition;
		}
		&.offscreen {
			@apply invisible h-0 overflow-hidden;

			& .banner {
				@apply invisible translate-y-full;
			}
		}

		&[data-sticky] {
			.close-wrap {
				@apply hidden;
			}

			.countdown {
				.countdown-desc-wrapper {
					@apply flex-col max-w-xl mx-auto md:max-w-2xl lg:max-w-3xl py-4 lg:py-14 2xl:py-16;

					.countdown-headline {
						@apply text-2xl mx-10 md:mx-0 md:text-4xl mb-2 md:mb-5 mt-4;
					}

					.countdown-period {
						font-size: 40px;
						line-height: 44px;
						
						@apply md:text-6xl;
					}
				}

				.button {
					@apply text-2xl py-3;

					width: 318px;
				}
			}
		}

		&:not([data-sticky]) {
			.countdown {
				@apply flex items-center justify-between;

				.countdown-desc-wrapper {
					@apply flex-1 justify-around md:justify-evenly items-center md:pl-5;

					.countdown-headline {
						@apply hidden md:block;
					}

					.countdown-period {
						@apply order-first text-xl md:text-2xl;
					}

					.button {
						width: 126px;
					}

					.deadline-desc {
						@apply hidden;
					}
				}
			}
		}
	}

	.embed {
		padding-top: 56.25%;
		position: relative;
		background-position: center;
		background-size: cover;
		margin-bottom: 1.5rem;

		& a.play {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			text-indent: -9999px;
			/* background: url('../images/icon-youtube.svg') center no-repeat; */
			background-position: center;
			background-repeat: no-repeat;
			background-size: 15%;
			opacity: 0.75;
			transition: opacity 150ms ease;

			&:hover {
				opacity: 1;
			}
		}

		& iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;

			& + a.play {
				display: none;
			}
		}
	}

	h1,
	h2,
	h3 {
		@apply font-serif;
	}

	.piechart {
		& circle.pie1 {
			transform: scale(0.95);
			transform-origin: center;
			fill: #eae7e1;
		}
		& circle.pie2 {
			stroke-width: 31.830988px;
			stroke-dashoffset: 25;
			stroke-dasharray: 0, 100;
			transform-origin: center;
			fill: transparent;
			transition: 1s ease;
		}
		& circle.pie3 {
			fill: #fff;
		}
	}
}
